<script lang="ts" setup>
import WhatsAppButton from '@/modules/core/components/WhatsAppButton.vue'

const { slug, location: locationUrl } = useRoute().params
const { exames: exams, cidade: LocationCity } = useRoute().query
// TODO: REMOVE ASSIM QUE VIRAR TODDAS AS CAMPANHAS PARA O NOVO FORMATO DE PATH
let slugs = []

if (Array.isArray(exams))
  slugs = exams as string[]
else
  slugs = !exams ? (slug as string)?.split(',') : (exams as string).split(',')

if (locationUrl === 'exames')
  navigateTo(`/${LocationCity}/exames?exames=${slugs?.join(',')}`)

useLocationParam().value = locationUrl as string

const showConfirmationModal = ref<boolean>(false)
const { data: branches } = useAsyncData('pending-schedules', async () => {
  if (!useCustomer().customer.value || !useCustomer().customer.value.id)
    return null

  const fetched = await $fetch<any>('/api/procedure/schedule', {
    query: {
      userId: useCustomer().customer.value.id,
    },
    headers: {
      AuthToken: useAuth().accessToken.value,
    },
  })

  showConfirmationModal.value = !!(fetched && Object.keys(fetched).length)

  return fetched
}, {
  watch: [() => useRoute().name, useAuth().accessToken],
  server: false,
})

const { setStep } = useCart()

onNuxtReady(() => {
  setStep(0)
})
</script>

<template>
  <div class="h-svh flex flex-col">
    <THeader />

    <main class="flex-1">
      <slot />

      <Dialog
        :show="showConfirmationModal"
        @close="showConfirmationModal = false"
      >
        <ProfileProcedureSchedulingConfirmationModal
          v-if="showConfirmationModal"
          :branches="branches"
          :show="showConfirmationModal"
          @close="showConfirmationModal = false"
        />
      </Dialog>
    </main>

    <WhatsAppButton />

    <TFooter />
  </div>
</template>
